import { TenantConfig } from '@tremaze/tenant-config-types';
import { map, Observable, shareReplay } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { TenantConfigDataSource } from '@tremaze/tenant-config-data-access';
import { HttpClient } from '@angular/common/http';

type ConfigInput = {
  name: TenantConfig['name'];
  value: string;
};

@Injectable()
export class TenantConfigDataSourceImpl implements TenantConfigDataSource {
  private readonly http = inject(HttpClient);

  private readonly _allAvailableConfigs = this.http
    .get<TenantConfig[]>('/public/config/available', {
      params: {
        skipTenantId: 'true',
        skipAuth: 'true',
      },
    })
    .pipe(
      map((r) => {
        for (const config of r) {
          if (config.dataType === 'BOOLEAN') {
            config.value = (config.value as any) === '1';
          }
        }
        return r.sort((a, b) => a.description.localeCompare(b.description));
      }),
      shareReplay(1),
    );

  getAllAvailableConfigs(): Observable<TenantConfig[]> {
    return this._allAvailableConfigs;
  }

  getConfigForCurrentTenant(): Observable<TenantConfig[]> {
    return this.http
      .get<TenantConfig[]>('/public/v2/config', {
        params: { skipAuth: 'true' },
      })
      .pipe(
        map((r) => {
          for (const config of r) {
            if (config.dataType === 'BOOLEAN') {
              config.value = (config.value as any) === '1';
            }
          }
          return r;
        }),
      );
  }

  updateConfig(config: TenantConfig[]): Observable<void> {
    const payload: ConfigInput[] = config.map((c) => {
      let value: string;

      if (typeof c.value === 'boolean') {
        value = c.value ? '1' : '0';
      } else {
        value = c.value.toString();
      }

      return {
        name: c.name,
        value,
      };
    });
    return this.http.put<void>('/config', payload);
  }
}

export const provideTenantConfigDataSource = () => ({
  provide: TenantConfigDataSource,
  useClass: TenantConfigDataSourceImpl,
});
