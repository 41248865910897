import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { L10n } from '@syncfusion/ej2-base';
import { syncfusionLocalizations } from '../l10n/syncfusion/german';

@Component({
  selector: 'tremaze-root',
  template: `
    <tremaze-announcement-anchor />
    <tour-step-template />
    <router-outlet />
  `,
  standalone: false,
  styles: [
    `
      tremaze-root {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      tremaze-root > router-outlet ~ * {
        flex: 1 1 auto;
        min-height: 0;
        overflow: auto;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  ngOnInit() {
    L10n.load(syncfusionLocalizations);
  }
}
