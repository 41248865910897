import { Announcement } from '@tremaze/announcement-types';
import { Observable } from 'rxjs';

export abstract class AnnouncementDataSource {
  abstract getGlobalAnnouncement(c?: {
    ignoreVersionsUntil?: number;
  }): Observable<Announcement | null>;

  abstract getAnnouncementForCurrentTenant(c?: {
    ignoreVersionsUntil?: number;
  }): Observable<Announcement | null>;
}
