import { Component, input, output, ViewEncapsulation } from '@angular/core';

import { Announcement } from '@tremaze/announcement-types';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { IconComponent } from '@tremaze/shared/ui/icon';

@Component({
  selector: 'tremaze-announcement-banner',
  standalone: true,
  imports: [MatAnchor, MatIconButton, IconComponent],
  template: `
    <div class="innerHTML announcement-banner__Body">
      <div [innerHTML]="announcement().text"></div>
      @if (announcement().link; as link) {
        <a class="announcement-banner__Link" [href]="link.url" target="_blank" mat-button>{{
            link.label
          }}</a>
      }
    </div>
    <button mat-icon-button (click)="clickedClose.emit()">
      <tremaze-icon icon="lnr-cross"></tremaze-icon>
    </button>
  `,
  styleUrl: './announcement-banner.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'announcement-banner primary-bg primary-fg',
    '[class.warn-bg]': 'announcement().warn',
    '[class.warn-fg]': 'announcement().warn',
    '[class.primary-fg]': '!announcement().warn',
    '[class.primary-bg]': '!announcement().warn'
  }
})
export class AnnouncementBannerComponent {
  readonly announcement = input.required<Announcement>();

  readonly clickedClose = output();
}
