import { inject, Injectable } from '@angular/core';
import { AnnouncementDataSource } from '@tremaze/announcement-data-access';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  interval,
  map,
  of,
  switchMap,
} from 'rxjs';
import { STORAGE } from '@tremaze/shared/core/storage';
import { toSignal } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs/operators';

const _storageKey = 'dismissedAnnouncementVersion';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
  private readonly _dataSource = inject(AnnouncementDataSource);
  private readonly _authService = inject(AuthV2Service);
  private readonly _storage = inject(STORAGE);

  private _latestDismissedAnnouncementVersion =
    this._getLatestDismissedAnnouncementVersion() ?? -1;

  /**
   * Observable that emits the selected tenant ID.
   * It derives from the authentication service and updates when the active tenant changes.
   */
  private readonly _selectedTenantId$ = this._authService.hasActiveTenant$.pipe(
    distinctUntilChanged(),
    switchMap((hasActiveTenant) =>
      hasActiveTenant
        ? this._authService.activeTenant$.pipe(
            map((tenant) => tenant.id),
            map((id) => id ?? null),
          )
        : of(null),
    ),
    distinctUntilChanged(),
  );

  /**
   * Observable stream that polls for announcements every 60 seconds.
   * It fetches tenant-specific or global announcements based on tenant selection.
   */
  private _announcement$ = interval(60 * 1000).pipe(
    startWith(0),
    switchMap(() => this._selectedTenantId$),
    switchMap((selectedTenantId) =>
      selectedTenantId
        ? this._dataSource
            .getAnnouncementForCurrentTenant({
              ignoreVersionsUntil:
                this._latestDismissedAnnouncementVersion ?? undefined,
            })
            .pipe(catchError(() => EMPTY))
        : this._dataSource
            .getGlobalAnnouncement({
              ignoreVersionsUntil:
                this._latestDismissedAnnouncementVersion ?? undefined,
            })
            .pipe(catchError(() => EMPTY)),
    ),
    filter(
      (announcement) =>
        (announcement?.version ?? -1) >
        this._latestDismissedAnnouncementVersion,
    ),
  );

  /**
   * Signal that emits the current announcement.
   * It is updated based on polling and tenant changes.
   */
  readonly announcement = toSignal(this._announcement$);

  /**
   * Dismisses the current announcement by storing its version in storage.
   * It updates the internal latest dismissed announcement version accordingly.
   */
  dismissCurrentAnnouncement() {
    const current = this.announcement();
    if (current) {
      this._storage.setItem(_storageKey, current.version.toString());
      this._latestDismissedAnnouncementVersion = current.version;
    }
  }

  /**
   * Retrieves the latest dismissed announcement version from storage.
   *
   * @returns {number | null} The latest dismissed announcement version as a number, or null if none is found or invalid.
   */
  private _getLatestDismissedAnnouncementVersion(): number | null {
    const item = this._storage.getItem(_storageKey);
    const version = item ? parseInt(item, 10) : null;
    if (!version) {
      return null;
    }
    if (isNaN(version)) {
      return null;
    }
    return version;
  }
}
