import { Component, effect, inject, signal } from '@angular/core';

import { AnnouncementService } from './announcement.service';
import { AnnouncementBannerComponent } from '@tremaze/announcement-banner';
import { simpleFadeInOutAnimation } from '../../../../animation/angular/simple/simple-animations';

@Component({
  selector: 'tremaze-announcement-anchor',
  imports: [AnnouncementBannerComponent],
  animations: [simpleFadeInOutAnimation],
  template: `
    @if (!hide()) {
      @if (service.announcement(); as announcement) {
        <tremaze-announcement-banner @simpleFadeInOut
          [announcement]="announcement"
          (clickedClose)="onClickClose()"
         />
      }
    }
  `,
})
export class AnnouncementAnchorComponent {
  readonly service = inject(AnnouncementService);

  readonly hide = signal(false);

  private _newAnnouncementEffect = effect(() => {
    const announcement = this.service.announcement();
    if (announcement) {
      this.hide.set(false);
    }
  }, {allowSignalWrites: true});

  onClickClose() {
    this.hide.set(true);
    this.service.dismissCurrentAnnouncement();
  }
}
