import { inject, Injectable } from '@angular/core';
import { AnnouncementDataSource } from './announcement-data-source';
import { Announcement } from '@tremaze/announcement-types';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AnnouncementDataSourceImpl implements AnnouncementDataSource {
  private readonly _http = inject(HttpClient);

  getGlobalAnnouncement(c?: {
    ignoreVersionsUntil?: number;
  }): Observable<Announcement | null> {
    return this._http.get<Announcement | null>('public/announcement', {
      params: {
        skipTenantId: true,
        skipAuth: true,
        ignoreVersionsUntil: c?.ignoreVersionsUntil ?? '',
      },
    });
  }

  getAnnouncementForCurrentTenant(c?: {
    ignoreVersionsUntil?: number;
  }): Observable<Announcement | null> {
    return this._http.get<Announcement | null>(
      'public/tenant/current/announcement',
      {
        params: {
          skipAuth: true,
          ignoreVersionsUntil: c?.ignoreVersionsUntil ?? '',
        },
      },
    );
  }
}

export const provideAnnouncementDataSource = () => ({
  provide: AnnouncementDataSource,
  useClass: AnnouncementDataSourceImpl,
});
