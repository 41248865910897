import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { TremazeDate } from '@tremaze/shared/util-date';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';

const dateInputRegex = /^(3[01]|[12][0-9]|0?[1-9])\.(1[0-2]|0?[1-9])\.(\d{4})$/;

const dateTimeInputRegex =
  /^(3[01]|[12][0-9]|0?[1-9])\.(1[0-2]|0?[1-9])\.(\d{4}),\s(2[0-3]|[01]?[0-9]):([0-5][0-9])$/;

@Injectable({ providedIn: 'root' })
export class TremazeDateAdapter extends DateAdapter<TremazeDate> {
  private readonly _nativeDateAdapter: NativeDateAdapter;

  constructor() {
    const dateLocale = 'de-DE'

    super();
    super.setLocale(dateLocale);
    this._nativeDateAdapter = new NativeDateAdapter();
    this._nativeDateAdapter.setLocale(dateLocale);
  }

  getYear(date: TremazeDate): number {
    if (!date) {
      return null;
    }
    return this._nativeDateAdapter.getYear(date);
  }

  getMonth(date: TremazeDate): number {
    if (!date) {
      return null;
    }
    return this._nativeDateAdapter.getMonth(date);
  }

  getDate(date: TremazeDate): number {
    if (!date) {
      return 0;
    }
    return this._nativeDateAdapter.getDate(date);
  }

  getDayOfWeek(date: TremazeDate): number {
    if (!date) {
      return null;
    }
    return this._nativeDateAdapter.getDayOfWeek(date);
  }

  getHours(date: TremazeDate): number {
    if (!date) {
      return null;
    }
    return this._nativeDateAdapter.getHours(date);
  }

  getMinutes(date: TremazeDate): number {
    if (!date) {
      return null;
    }
    return this._nativeDateAdapter.getMinutes(date);
  }

  getSeconds(date: TremazeDate): number {
    if (!date) {
      return null;
    }
    return this._nativeDateAdapter.getSeconds(date);
  }

  setTime(
    target: TremazeDate,
    hour: number,
    minute: number,
    second: number,
  ): TremazeDate {
    if (!target) {
      return null;
    }
    return target.set({ hour, minute, second });
  }

  sameDate(first: TremazeDate | null, second: TremazeDate | null): boolean {
    return first?.isSame(second) ?? false;
  }

  addSeconds(date: TremazeDate | null, amount: number): TremazeDate {
    if (!date) {
      return null;
    }
    return date.add(amount, 'second');
  }

  deserialize(value: any): TremazeDate | null {
    return TremazeDate.deserialize(value);
  }

  compareDate(first: TremazeDate, second: TremazeDate): number {
    if (!first || !second) {
      return first ? 1 : second ? -1 : 0;
    }
    return this._nativeDateAdapter.compareDate(first, second);
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._nativeDateAdapter.getMonthNames(style);
  }

  getDateNames(): string[] {
    return this._nativeDateAdapter.getDateNames();
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._nativeDateAdapter.getDayOfWeekNames(style);
  }

  getYearName(date: TremazeDate): string {
    if (!date) {
      return '';
    }
    return this._nativeDateAdapter.getYearName(date);
  }

  getNumDaysInMonth(date: TremazeDate): number {
    if (!date) {
      return 0;
    }
    return this._nativeDateAdapter.getNumDaysInMonth(date);
  }

  today(): TremazeDate {
    return TremazeDate.getNow();
  }

  format(date: TremazeDate | null, displayFormat: any): string {
    if (!(date instanceof TremazeDate) || !this.isValid(date)) {
      return '';
    }
    return this._nativeDateAdapter.format(date, displayFormat);
  }

  addCalendarYears(date: TremazeDate, years: number): TremazeDate {
    if (!date) {
      return null;
    }
    return date.clone().add(years, 'year');
  }

  addCalendarMonths(date: TremazeDate, months: number): TremazeDate {
    if (!date) {
      return null;
    }
    return date.clone().add(months, 'month');
  }

  addCalendarDays(date: TremazeDate, days: number): TremazeDate {
    if (!date) {
      return null;
    }
    return date.clone().add(days, 'day');
  }

  toIso8601(date: TremazeDate): string {
    if (!date) {
      return '';
    }
    return date.toISOString();
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof TremazeDate;
  }

  isValid(date: TremazeDate): boolean {
    return date && this._nativeDateAdapter.isValid(date);
  }

  invalid(): TremazeDate {
    return TremazeDate.fromDate(this._nativeDateAdapter.invalid());
  }

  getValidDateOrNull(obj: unknown): TremazeDate | null {
    return super.getValidDateOrNull(obj);
  }

  createDate(year: number, month: number, date: number): TremazeDate {
    return new TremazeDate(TremazeDate.UTC(year, month, date));
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  clone(date: TremazeDate): TremazeDate {
    if (!date) {
      return null;
    }
    return date.clone();
  }

  parse(value: any, parseFormat: any): TremazeDate | null {
    if (typeof value === 'number') {
      return new TremazeDate(value);
    }
    if (typeof value === 'string') {
      if (dateTimeInputRegex.test(value)) {
        const [date, time] = value.split(', ');
        const [day, month, year] = date.split('.').map(Number);
        const [hour, minute] = time.split(':').map(Number);
        return new TremazeDate(year, month - 1, day, hour, minute);
      } else if (dateInputRegex.test(value)) {
        const [day, month, year] = value.split('.').map(Number);
        return new TremazeDate(year, month - 1, day);
      }
    }
    return null;
  }
}
