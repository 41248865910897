import { Environment } from './type';

export const environment: Environment = {
  production: true,
  clientId: 'tagea-cc',
  authIssuer: 'https://keycloak.tremaze.de/auth/realms/tremaze-prod',
  oidcConfigURL:
    'https://keycloak.tremaze.de/auth/realms/tremaze-prod/.well-known/openid-configuration',
  basePath: 'https://api.tagea.app/',
  enableServiceWorker: true,
  lightThemeLogoPath: '/assets/images/dvelop-logo-poison.png',
  darkThemeLogoPath: '/assets/images/dvelop-logo-invers.png',
  iconPath: '/assets/images/dvelop-icon.png',
  productName: 'd.velop care',
  disableFreshChat: true,
  stage: 'PROD',
  enableVitaAdvisorFor: null,
};
